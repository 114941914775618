.users-manager .actions-btn {
    padding: 5px;
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 5px;
    width: 100%;
}

.users-manager .loader-img {
    height: 70px;
}

.users-manager .modal .close {
    background-color: white;
    border: none;
}