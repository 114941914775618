.shop-item-details .original-image {
    object-fit: contain;
    width: 90%;
    object-fit: contain;
    border-radius: 10px;
    margin: 20px;
    box-shadow: 0 0 30px 5px rgba(25, 25, 25, 0.1);
}

.shop-item-details .editor-container>.section>.section-container {
    padding: 0;
}

.shop-item-details .old-price {
    margin-right: 10px;
}

.shop-item-details .rc-md-editor, .shop-item-details .rc-md-editor .editor-container>.section {
    border: none
}

.shop-item-details .breadcrumb *, .shop-item-details .breadcrumb *:before {
    color: black;
    text-decoration: none;
    font-size: 18px;
}

.shop-item-details .add-cart-btn {
    font-size: 20px;
}