.inconsistent-status .modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
    --bs-modal-header-border-color: olive;
}

.inconsistent-status .modal .modal-dialog {
    width: 100%;
}

.inconsistent-status .modal-footer {
    margin-top: 20px;
    padding: 0;
}

.inconsistent-status .modal-footer button {
    margin: 0;
}

.inconsistent-status .modal button.close {
    background-color: white;
    border: none;
    z-index: 1000;
}

.inconsistent-status .modal-footer {
    display: flex;
    justify-content: space-between;
}
