.category-manager .modal .form-group, .category-manager .modal [type="submit"] {
    margin-top: 10px;
}

.category-manager .table .btn-warning {
    background-color: #FFD54F;
    margin-right: 20px;
}

.category-manager .table .btn-danger, .category-manager .modal .btn-danger {
    background-color: #F44336;
}

.category-manager .modal button.close {
    background-color: white;
    border: none;
}