.shop-cart-added-modal .modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.shop-cart-added-modal .modal .modal-dialog {
    width: 100%;
}

.shop-cart-added-modal .added-img {
    height: 150px;
    object-fit: cover;
}

.shop-cart-added-modal .item-title {
    width: 100%;
    margin-left: 15px;
}

.shop-cart-added-modal .modal-footer {
    margin-top: 20px;
    padding: 0;
}

.shop-cart-added-modal .modal-footer button {
    margin: 0;
}

.shop-cart-added-modal .modal-footer {
    display: flex;
    justify-content: space-between;
}

.shop-cart-added-modal .modal-footer * {
    z-index: 1000;
}
