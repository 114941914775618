.animation-ctn{
    text-align:center;
}

.animation-ctn .animation-ctn-circle {
    stroke-dasharray:480px, 480px;
    stroke-dashoffset: 960px;
}

.animation-ctn .animation-ctn-polyline {
    stroke-dasharray:100px, 100px;
    stroke-dashoffset: 200px;
    stroke-width: 3px;
}

@-webkit-keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 200px
    }
}

@-ms-keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 200px
    }
}

@keyframes checkmark {
    0% {
        stroke-dashoffset: 100px
    }

    100% {
        stroke-dashoffset: 0px
    }
}

@-webkit-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 480px

    }

    100% {
        stroke-dashoffset: 960px;

    }
}

@-ms-keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 240px
    }

    100% {
        stroke-dashoffset: 480px
    }
}

@keyframes checkmark-circle {
    0% {
        stroke-dashoffset: 480px
    }

    100% {
        stroke-dashoffset: 960px
    }
}

@keyframes colored-circle {
    0% {
        opacity:0
    }

    100% {
        opacity:100
    }
}

.inlinesvg .svg svg {
    display: inline
}

.icon--order svg polyline {
    -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
    animation: checkmark 0.25s ease-in-out 0.7s backwards;
    transition: all 0.5s;
}

.icon--order svg circle {
    -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
    animation: checkmark-circle 0.6s ease-in-out backwards;
    transition: all 0.5s;
}
.icon--order svg circle#colored {
    -webkit-animation: colored-circle 0.6s ease-in-out 0.7s backwards;
    animation: colored-circle 0.6s ease-in-out 0.7s backwards;
}

.animation-ctn g {
    transition: all 0.5s;
}