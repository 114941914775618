.modal-window .modal-dialog .modal-content {
    -webkit-box-shadow: 0 0 20px 10px rgba(25, 25, 25, 0.1);
    box-shadow: 0 0 20px 10px rgba(25, 25, 25, 0.1);
    border: none;

    animation: fadeInDown;
    animation-duration: 0.5s;
}

.modal-window .modal-dialog .modal-content.fadeOut {
    animation: fadeOutUp;
    animation-duration: 0.5s;
    opacity: 0;
}

.modal-window .modal-footer {
    border-top: none;
}