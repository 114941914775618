.shop-cart-exceeds-quantity .modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
    --bs-modal-header-border-color: olive;
}

.shop-cart-exceeds-quantity .modal .modal-dialog {
    width: 100%;
}

.shop-cart-exceeds-quantity .modal-footer {
    margin-top: 20px;
    padding: 0;
}

.shop-cart-exceeds-quantity .modal-footer button {
    margin: 0;
}

.shop-cart-exceeds-quantity .modal-footer {
    display: flex;
    justify-content: space-between;
}
