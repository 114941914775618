.shipping-country-modal .modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.shipping-country-modal .no-country-hint {
    font-style: italic;
    font-size: 13px;
    color: gray;
}