.shop-management-dashboard .dashboard-btn {
    container-type: inline-size;
    aspect-ratio: 1;
    border: none;
    border-radius: 1rem;
    place-content: center;
    background: none;
    --shadow: -0.5rem -0.5rem 1rem rgba(255, 255, 255, 0.75), 0.5rem 0.5rem 1rem rgba(128, 128, 128, 0.5);
    box-shadow: var(--shadow);
    outline: none;
    transition: all 0.1s;
    font-size: 20px;
    width: 100%;
    text-align: center;
    color: black;
    text-decoration: none;
    scale: 1.0;
}

.shop-management-dashboard .dashboard-btn > span {
    background: white;
}

.shop-management-dashboard .col-6 {
    padding: 20px;
}

.shop-management-dashboard .dashboard-btn:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 5%;
    top: 5%;
    width: 90%;
    height: 90%;
    opacity: 0.5;
    background-repeat: no-repeat !important;
    background-position: 50% 0 !important;
    background-size: cover !important;
    z-index: -1;
}

.shop-management-dashboard .dashboard-btn:hover,
.shop-management-dashboard .dashboard-btn:focus-visible {
    font-size: 25px;
    scale: 1.1;
}

.shop-management-dashboard .dashboard-btn.orders-btn:before {
    background: url('../../../assets/shop/management/shop_orders.png');
}

.shop-management-dashboard .dashboard-btn.items-btn:before {
    background: url('../../../assets/shop/management/shop_items.png');
}

.shop-management-dashboard .dashboard-btn.categories-btn:before {
    background: url('../../../assets/shop/management/shop_categories.png');
}

.shop-management-dashboard .dashboard-btn.deliveries-btn:before {
    background: url('../../../assets/shop/management/shop_delivery.png');
}

.shop-management-dashboard .dashboard-btn.discounts-btn:before {
    background: url('../../../assets/shop/management/shop_discount.png');
}

.shop-management-dashboard .dashboard-btn.users-btn:before {
    background: url('../../../assets/shop/management/shop_users.png');
}

.shop-management-dashboard .dashboard-btn.mail-templates-btn:before {
    background: url('../../../assets/shop/management/mail_templates.png');
}

.shop-management-dashboard .dashboard-btn.mail-config-btn:before {
    background: url('../../../assets/shop/management/mail_config.png');
}

.shop-management-dashboard .dashboard-btn.send-newsletter-btn:before {
    background: url('../../../assets/shop/management/newsletter.png');
}

.shop-management-dashboard .dashboard-btn.seller-dashboard-btn:before {
    background: url('../../../assets/shop/management/payment_system.png');
}

.shop-management-dashboard .dashboard-btn.analytics-btn:before {
    background: url('../../../assets/shop/management/analytics.png');
}

.shop-management-dashboard .shop-management-title {
    margin-bottom: 20px;
    text-align: center;
}

.shop-management-dashboard .user-management-title,
.shop-management-dashboard .mail-management-title,
.shop-management-dashboard .external-resources-title {
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
}

.shop-management-dashboard .container {
    margin-bottom: 20px;
}
