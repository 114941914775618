.item-manager .shop-item-preview .wrapper {
    animation: none;
}

.item-manager .infinite-scroll-component {
    overflow: visible !important;
}

.item-manager .modal .form-group, .item-manager .modal [type="submit"] {
    margin-top: 10px;
}

.item-manager .modal .upload__image-wrapper button {
    height: 50px;
    align-self: center;
}

.item-manager .modal .upload__image-wrapper .upload-image-btn {
    margin-right: 50px;
}

.item-manager .modal .upload__image-wrapper .upload-image-preview {
    margin-right: 20px;
}

.item-manager .modal .shop-item-asset-container {
    box-shadow: 0 0 30px 5px rgba(25, 25, 25, 0.1);
    border: solid 1px darkgreen;
    border-radius: 10px;
    padding: 20px;
}

.item-manager .modal .shop-item-asset-container .remove-asset-btn {
    float: right;
    margin-top: 20px;
}

.item-manager .add-asset-btn {
    border: none;
    margin-left: 10px;
}

.item-manager .modal .form-check-input:checked {
    background-color: darkgreen !important;
    border-color: darkgreen !important;
}

.item-manager .modal .save-btn {
    background-color: darkgreen;
    border: none;
}

.item-manager .modal .close {
    background-color: white;
    border: none;
}

.item-manager .modal .alert-danger {
    background-color: #FCE4EC;
}

.item-manager .modal .remove-asset-btn {
    background-color: red;
    border: none;
}

.item-manager [data-react-beautiful-dnd-draggable] {
    width: 100%;
    max-width: 300px;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
}

.item-manager .shop-items-loader-img {
    height: 70px;
}

.item-manager .archived-item {
    position: relative;
}

.item-manager .archived-item:hover::before {
    top: calc(40% - 10px);
}

.item-manager .archived-item::before {
    content: "Archived";
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    font-size: 48px;
    color: rgba(0, 0, 0, 0.4);
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;
    pointer-events: none;
    z-index: 1;
    transition: ease all .3s;
}

@media (min-width: 576px) {
    .item-manager .modal-dialog {
        max-width: 800px;
        margin: 1.75rem auto;
    }
}