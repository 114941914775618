/* Common style set */

*, pre {
    font-family: 'LetMeShowYou', 'Pangolin', Arial, serif;
    text-transform: uppercase;
}

.btn-primary:disabled, a.btn-primary.disabled-link {
    background-color: #AFB42B;
    border: none;
}

a.btn-primary.disabled-link {
    pointer-events: none;
}

.text-primary {
    color: olive !important;
}

.btn-primary {
    background-color: olive;
    border: none;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    background-color: darkolivegreen !important;
    border: none;
}

.btn-secondary:disabled {
    background: #D1C4E9;
    border: none;
}

.btn-secondary {
    background: #B39DDB;
    border: none;
}

.badge-secondary {
    background: #B39DDB;
    color: white;
}

.btn-secondary:hover, .btn-secondary:active, .btn-secondary:focus { 
    background: #9575CD !important;
    border: none;
}

.badge-warning {
    background: #FFEB3B;
    color: black;
}

.badge-success{
    background: #4CAF50;
    color: white;
}

.badge-danger {
    background: #F44336;
    color: white;
}

/* Modal styles */

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover{
    background-color: darkgreen;
}

.required > svg {
    height: 7px;
    width: 10px;
    vertical-align: top;
    fill: red;
}

.modal.modal-window {
    z-index: 1000000;
}

.centered-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


/* Responsive tables */

table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
}

table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
}

table tr {
    border: 1px solid #ddd;
    padding: .35em;
}

table th,
table td {
    padding: .625em;
    text-align: center;
}

table th {
    background: #EDE7F6;
}

@media screen and (max-width: 600px) {
    table {
        border: 0;
    }

    table caption {
        font-size: 1.3em;
    }

    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: .8em;
        text-align: right;
    }

    table td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    table td:last-child {
        border-bottom: 0;
    }
}

/* Modal panel */

.modal {
    --bs-modal-header-border-color: olive;
}

.modal button.close {
    background-color: white;
    border: none;
    z-index: 1000;
}