.gallery-content .ReactGridGallery_tile-viewport {
    display: inline-flex;
}

.ReactGridGallery_tile-viewport img {
    max-width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
}

.gallery-content .ReactGridGallery_tile-viewport img {
    margin-left: 0 !important;
    display: inline-table;
}

.gallery-content .ReactGridGallery > div {
    justify-content: center;
}

.gallery-content .gallery-tab-title {
    height: 50px;
    font-size: 40px;
    letter-spacing: -5px;
}

.gallery-content .gallery-tabs a {
    text-decoration: none;
}

.gallery-content .gallery-tabs .gallery-tab-title.portfolio {
    color: #ff643c;
}

.gallery-content .gallery-tabs .gallery-tab-title.art {
    color: #78b4bc;
}

.gallery-content .gallery-tabs img {
    height: 50px;
}

.gallery-content .gallery-tabs a {
    cursor: pointer;
    margin: 0 10px;
}

.gallery-content .gallery-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 26px;
}

.ReactGridGallery_tile {
    background: white !important;
}

.ril-outer {
    background-color: rgba(255, 255, 255, 0.85) !important;
}

.ril-toolbar {
    background-color: rgba(255, 205, 199, 0.2) !important;
}

.ril-close {
    background-color: darkolivegreen !important;
}

.ril-prev-button, .ril-next-button, .ril-zoom-in, .ril-zoom-out {
    background-color: hotpink !important;
}

.ril-prev-button, .ril-next-button {
    opacity: 50% !important;
    padding: 20px 30px !important;
}

.ril-outer .spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-60px, -60px);
    z-index: 100000;
}

.ril-outer .spinner img {
    width: 120px;
    height: 120px;
}

.ril-inner img {
    touch-action: none;
    -webkit-touch-callout: none;
}

.ReactModal__Overlay {
    z-index: 1000000 !important;
}


