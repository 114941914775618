.success-payment .confirmation-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
}


@media (max-width: 768px) {
    .success-payment .container > .d-flex {
        flex-direction: column;
    }
}