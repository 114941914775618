.home-content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
}

.home-content .menu-item-buttons, .menu-item-buttons * {
    height: 50px;
}

.home-content .menu-item-images, .menu-item-images * {
    height: 300px;
}

.home-content .menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-content .menu-item img {
    object-fit: contain;
    height: 100%;
    width: auto;
}

.home-content .header-img {
    max-height: 300px;
}

.home-content .menu-item-buttons,
.home-content .flowers-container {
    margin-top: 30px;
}

.home-content .flowers-container, .flowers-container img {
    max-height: 70px;
}

.menu-images,
.menu-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
}

.home-content .title-container, .home-content .title-container img {
    max-height: 100px;
    margin-top: 10px;
    margin-bottom: 40px;
}

@media (max-width: 768px) {
    .home-content .menu-images,
    .home-content .menu-buttons {
        flex-direction: column;
        align-items: center;
    }
    .home-content .menu-item {
        margin-bottom: 20px;
    }

    .home-content .header-img {
        content: url(../../assets/home/home_small.PNG);
        max-height: 200px;
    }

    .home-content .title-container {
        margin-top: 30px;
    }
}