.loading-content .spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-60px, -60px);
    z-index: 100000;
}

.loading-content .spinner img {
    width: 120px;
    height: 120px;
}

.loading-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-content, .loading-content * {
    opacity: 1;
    transition: opacity 0.6s ease-out;
}

.loading-content.fade-out, .loading-content.fade-out * {
    opacity: 0;
    pointer-events: none;
}