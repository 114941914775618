.shop-profile .card {
    border: none;
    -webkit-box-shadow: 0 0 20px 10px rgba(25, 25, 25, 0.1);
    box-shadow: 0 0 20px 10px rgba(25, 25, 25, 0.1);
}

.shop-profile .card-header {
    border-color: mediumpurple;
    background: lavender;
}

.shop-profile .profile-card {
    animation: fadeIn 500ms;
}

.shop-profile .order-row-animated {
    animation: expandItem 0.5s ease-out forwards;
}

.shop-profile .items-container {
    transition: max-height 0.5s;
}

.shop-profile .infinite-scroll-component {
    overflow: unset !important;
}

.shop-profile .order-badge {
    width: auto;
    font-size: 1rem;
}

.shop-profile .order-badge svg {
    width: 1rem;
    height: 1rem;
    margin-right: 5px;
}

.shop-profile .order-item {
    height: 100px;
    margin-top: 20px;
}

.shop-profile .order-items-loader {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    background: white;
}

.shop-profile .toggle-text-btn {
    position: absolute;
    bottom: -25px;
    right: 0;
    width: 100%;
    cursor: pointer;
    border: none;
    background: linear-gradient(rgba(100, 100, 100, 0), rgba(150, 150, 150, 0.1));
    font-size: 13px;
}

.shop-profile .order-item h6 {
    font-size: 1.2rem;
}

@media (max-width: 768px) {
    .shop-profile .order-row .order-buttons * {
        padding: 6px !important;
    }

    .shop-profile .order-row .order-buttons {
        min-height: 0 !important;
    }
}

.shop-profile .order-row .order-buttons * {
    padding: 2px;
}
    
.shop-profile .order-row .order-buttons {
    min-height: 100px;
}

.shop-profile .order-item .order-item-info {
    font-size: 0.9rem;
}

.shop-profile .order-item-preview {
    height: 100%;
    margin-right: 10px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 0 30px 5px rgba(25, 25, 25, 0.1);
}

.shop-profile .loader-img {
    height: 70px;
}