.discount-manager .react-datepicker-wrapper {
    width: 100%;
}

.discount-manager .modal .form-group, .discount-manager .modal [type="submit"] {
    margin-top: 10px;
}

.discount-manager .modal .form-check-input:checked {
    background-color: darkgreen !important;
    border-color: darkgreen !important;
}

.discount-manager .modal .checkbox-label {
    margin-right: 10px;
}

.discount-manager .table .btn-warning {
    background-color: #FFD54F;
    margin-right: 20px;
}

.discount-manager .table .btn-danger, .discount-manager .modal .btn-danger {
    background-color: #F44336;
}
