.delivery-manager .modal .form-group, .delivery-manager .modal [type="submit"] {
    margin-top: 10px;
}

.delivery-manager .modal button.close {
    background-color: white;
    border: none;
}

.delivery-manager .table .btn-warning {
    background-color: #FFD54F;
    margin-right: 20px;
}

.delivery-manager .table .btn-danger, .delivery-manager .modal .btn-danger {
    background-color: #F44336;
}
