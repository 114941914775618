.shop-cart .card {
    border: none;
    -webkit-box-shadow: 0 0 20px 10px rgba(25, 25, 25, 0.1);
    box-shadow: 0 0 20px 10px rgba(25, 25, 25, 0.1);
}

.shop-cart .card-header {
    border-color: mediumpurple;
    background: lavender;
}

.shop-cart .rc-md-editor, .shop-cart .rc-md-editor .editor-container>.section {
    border: none
}

.shop-cart .rc-md-editor .section-container {
    padding: 0 !important;
}

.shop-cart .item-text {
    transition: max-height 0.5s;
}

.shop-cart .item-title {
    color: black;
    text-decoration: none;
}

.shop-cart .toggle-text-btn {
    position: absolute;
    bottom: -25px;
    right: 0;
    width: 100%;
    cursor: pointer;
    border: none;
    background: linear-gradient(rgba(100, 100, 100, 0), rgba(150, 150, 150, 0.1));
    font-size: 13px;
}

.shop-cart .toggle-text-btn:hover {
    background: linear-gradient(rgba(100, 100, 100, 0), rgba(150, 150, 150, 0.2));
    border-color: #ccc;
}


.shop-cart .item-img {
    object-fit: cover;
    border-radius: 10px;
    max-height: 170px;
    box-shadow: 0 0 30px 5px rgba(25, 25, 25, 0.1);
}

.shop-cart .input-group object svg path {
    fill: white;
}

@media (max-width: 991.98px) {
    .shop-cart .item-img {
        max-height: unset;
    }

    .shop-cart .quantity-input {
        width: 100%;
    }
}

.shop-cart .quantity-input input[type=number] {
    -moz-appearance: textfield;
    margin: 0;
    background: white;
}

.shop-cart .payment-methods {
    width: 100%;
}

.shop-cart .quantity-input input::-webkit-outer-spin-button,
.shop-cart .quantity-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

@keyframes expandItem {
    0% {
        opacity: 0;
        transform: scale(0.8);
        height: 0;
    }
    100% {
        opacity: 1;
        transform: scale(1);
        height: auto;
    }
}

.cart-row {
    animation: expandItem 0.5s ease-out forwards;
}

.shop-cart .cart-row:last-of-type {
    margin-bottom: 9px !important;
}

.shop-cart .cart-row h3 {
    font-size: 18px;
}

.shop-cart .cart-row h5 {
    font-size: 16px;
}

.shop-cart .form-control:focus {
    box-shadow: none;
    border: var(--bs-border-width) solid var(--bs-border-color);
}

.shop-cart .shipping-price-label {
    font-size: 14px;
}

.shop-cart .no-shipping-solution {
    font-style: italic;
    font-size: 13px;
    color: gray;
}

.shop-cart .validate-promo {
    padding: 0;
    background: none !important;
    margin-left: 0 !important;
}

.shop-cart .validate-promo:hover,
.shop-cart .validate-promo:focus,
.shop-cart .validate-promo:disabled {
    background: none !important;
}

.shop-cart .promo-input, .shop-cart .promo-input:focus {
    border-top: none;
    border-bottom: none;
    border-left: none;
}

.shop-cart .summary-btn, .shop-cart .item-controls {
    animation: fadeInDown 200ms;
}

.shop-cart .summary-btn.d-none, .shop-cart .item-controls.d-none {
    animation: fadeOutUp 200ms;
}