.order-manager .order-badge svg {
    width: 1rem;
    height: 1rem;
    margin-right: 5px;
}

.order-manager .order-item-preview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 0 30px 5px rgba(25, 25, 25, 0.1);
}

.order-manager .info-card {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.order-manager .delivery-card {
    border-radius: 0 !important;
    margin: 0 !important;
}

.order-manager .delivery-card-header {
    background: #EDE7F6;
    border-radius: 0 !important;
}

.order-manager .info-card p * {
    font-size: 16px !important;
}

.order-manager .info-card p:last-child {
    margin-bottom: 0;
}
