.about-info {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.about-content .about-info > * {
    max-width: 50%;
}

.about-content .about-info a {
    color: hotpink;
}

.about-content .about-info-text {
    margin-top: 20px;
}

@media (max-width: 768px) {
    .about-content .about-info > * {
        max-width: 90%;
    }
}