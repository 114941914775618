nav .navbar-brand img {
    height: 200px;
}

nav .nav-link .link-title, nav .nav-link .img-fluid {
    max-height: 50px;
    max-width: 50px;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -5px;
}

@media (max-width: 992px) {
    nav .nav-link .link-title {
        font-size: 31px;
    }

    nav .nav-link .link-title, nav .nav-link .img-fluid {
        max-height: 31px;
        max-width: 31px;
    }

    nav .nav-item {
        margin: 0 !important;
    }
}

@media (max-width: 768px) {
    nav .nav-link .link-title {
        font-size: 40px;
    }

    nav .nav-link .link-title, nav .nav-link .img-fluid {
        max-height: 50px;
        max-width: 50px;
    }
}

nav .nav-link {
    padding-left: 0;
    padding-right: 0;
}

nav .nav-link .shop-nav-title {
    color: #30743c;
}

nav .nav-link .illustration-nav-title {
    color: #c0c434;
}

nav .nav-link .about-nav-title {
    color: #ffc4d4;
}

nav .nav-link .cart-nav-title {
    color: #ff8444;
}

nav .nav-link .profile-nav-title {
    color: #88c4dc;
}

nav .nav-link .faq-nav-title {
    color: #7986CB;
}

nav .nav-link .admin-nav-title {
    color: #F48FB1;
}

nav .navbar-collapse {
    justify-content: left;
}

nav .nav-item {
    margin: 0 5px;
}

nav .navbar-toggler-icon {
    width: 50px;
    height: 50px;
    background: none;
}

nav .rounded-pill {
    top: 0;
    right: -30px;
    font-size: 12px;
    width: 20px;
    letter-spacing: 0;
    padding-left: 0;
    padding-right: 0;
}

nav .navbar-toggler {
    border: 0;
    padding: 0;
    background-size: contain;
}

nav .navbar-toggler:hover {
    width: 55px;
    height: 55px;
}

nav .navbar-toggler:focus {
    box-shadow: none;
}