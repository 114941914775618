.shop-filters .reset-shop-category-btn {
    color: mediumpurple;
    font-weight: 800;
    text-align: left;
}

.shop-filters .reset-shop-category-btn:focus {
    outline: none;
    box-shadow: none;
}

.shop-filters .filter-category-node span:hover {
    text-decoration: underline;
}

@keyframes expand {
    0% {
        max-height: 0;
        opacity: 0;
    }
    100% {
        max-height: 1000px;
        opacity: 1;
    }
}

.shop-filters .filter-category-node {
    animation: expand 0.3s ease-out forwards;
}

.shop-filters .filter-category-node .arrow {
    margin: auto;
    float: right;
}

.shop-filters .filter-category-content {
    height: 40px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: -1px 0;
    border: none;
    padding-left: 0;
}

.shop-filters .filter-category-content.disabled-category {
    cursor: not-allowed;
}

.shop-filters .filter-category-content.disabled-category .filter-category-name {
    color: gray;
}

.shop-filters .filter-category-content .filter-category-name {
    margin: auto;
    width: 100%;
}