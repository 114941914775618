.orders-manager .order-badge svg {
    width: 1rem;
    height: 1rem;
    margin-right: 5px;
}

.orders-manager .items-container {
    transition: max-height 0.5s;
}

.orders-manager .order-items-loader {
    position: absolute;
    width: 100%;
    left: 0;
    background: white;
}

.orders-manager .toggle-text-btn {
    position: absolute;
    bottom: -25px;
    right: 0;
    width: 100%;
    cursor: pointer;
    border: none;
    background: linear-gradient(rgba(100, 100, 100, 0), rgba(150, 150, 150, 0.1));
    font-size: 13px;
}

.orders-manager .order-badge {
    width: auto;
    font-size: 1rem;
}

.orders-manager .order-item-preview {
    height: 100%;
    margin-right: 10px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 0 30px 5px rgba(25, 25, 25, 0.1);
}

.orders-manager .order-item {
    height: 100px;
    margin-top: 20px;
}

.orders-manager .loader-img {
    height: 70px;
}

.orders-manager .infinite-scroll-component {
    overflow: unset !important;
}
