.shipping-details-modal .form-group {
    margin-top: 10px;
}

.shipping-details-modal .modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
    --bs-modal-header-border-color: olive;
    overflow: hidden;
}
