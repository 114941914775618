.faq .card {
    border: none;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 20px 10px rgba(25, 25, 25, 0.1);
    box-shadow: 0 0 20px 10px rgba(25, 25, 25, 0.1);
}

.faq .card .card-header {
    background: lavender;
    border-bottom: solid 1px mediumpurple;
}

.faq .modal-backdrop {
    --bs-backdrop-bg: white !important;
    --bs-backdrop-opacity: 0.7 !important;
}

.faq ul {
    list-style: none;
    padding: 0;
}