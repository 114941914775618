.shop-item-preview .wrapper {
    margin: 10px;
    padding-top: 280px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    -webkit-box-shadow: 0 0 20px 10px rgba(25, 25, 25, 0.1);
    box-shadow: 0 0 20px 10px rgba(25, 25, 25, 0.1);
    -webkit-transition: ease all .3s;
    transition: ease all .3s;
    border-radius: 10px;
    
    animation: zoomIn;
    animation-duration: 0.3s;
    max-width: 280px;
}

.shop-item-preview .wrapper:hover {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.shop-item-preview .wrapper:hover .card_img {
    top: 0;
}

.shop-item-preview .wrapper .heart{
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    width: 25px;
    height: 25px;
    color: #fff;
}

.shop-item-preview .wrapper .heart svg, .wrapper .heart path, .wrapper .heart circle {
    stroke: currentColor;
    fill: transparent;
}

.shop-item-preview .wrapper .heart {
    cursor: pointer;
}

.shop-item-preview .color_bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
}

.shop-item-preview .badge {
    z-index: 1000;
}

.shop-item-preview .card_img {
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    height: 280px;
    -webkit-transition: ease all .3s;
    transition: ease all .3s;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.shop-item-preview .cardInfo {
    display: block;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.shop-item-preview .cardInfo .item-title {
    width: 100%;
}

.shop-item-preview .action {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 15px;
}

.shop-item-preview .priceGroup {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.shop-item-preview .priceGroup .price {
    font-size: 18px;
    color: #000;
    text-align: left;
}

.shop-item-preview .priceGroup .price.old_price {
    font-size: 14px;
    text-decoration: line-through;
    text-align: left;
}

.shop-item-preview .priceGroup .old-price-stub {
    font-size: 14px;
}

.shop-item-preview .priceGroup .old-price-stub:after {
    content: '‎';
    color: #FFF;
}

.shop-item-preview .priceGroup .price.newPrice {
    color: #000;
    font-size: 18px;
}

.shop-item-preview .cart {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    width: 35px;
    height: 35px;
    -webkit-transition: ease all .3s;
    transition: ease all .3s;
}

.shop-item-preview .cart-img {
    width: 100%;
    height: 100%;
}

.shop-item-preview .cart:hover {
    cursor: pointer;
    width: 40px;
    height: 40px;
}

.shop-item-preview .cart svg, .shop-item-preview .cart path, .cart circle {
    stroke: currentColor;
    fill: transparent;
    -webkit-transition-delay: .2s;
    transition-delay: .2s;
}

.shop-item-preview .wrapper {
    margin: auto auto 25px;
}

.shop-item-preview .out-of-stock {
    font-size: 15px;
    margin: auto;
}